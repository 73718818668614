body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Calibri",
    "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(233, 233, 233);
}
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  outline: none !important;
}
