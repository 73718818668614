* {
  box-sizing: border-box;
}
.progress {
  background-color: #c7c7c7;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 400px;
  /* bottom: 53px; */
}

.progress-done {
  background: linear-gradient(to top right, #454545 33%, #171717 100%);
  box-shadow: 0 3px 3px -5px #454545, 0 2px 5px #171717;
  border-radius: 20px;
  color: #fff;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0%;
  opacity: 0;
  padding: 10px;
  transition: 1s ease 0.3s;
}
.progress-done > h6 {
  margin-top: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 700px) {
  .progress {
    width: 280px;
  }
}
