.header {
  /* background-image: url("header-background.jpg"); */

  min-height: fit-content;
  height: 100%;
  background-repeat: no-repeat;
  color: white;
  object-fit: contain;
  background-size: cover;
  position: relative;
  overflow-x: hidden;
  /* background-color: rgba(0, 0, 0, 0.4); */
}
.video-background {
  position: relative;
  width: 100%;
  min-height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.1); */
}

.header_overlay {
  z-index: 100;
  position: absolute;
  top: 0%;
  width: 100%;
}
.video_background {
  z-index: -2;

  position: absolute;
  top: 0;
  object-fit: contain;
  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* opacity: 0.6; */
}
#video {
  z-index: -1;
  overflow-x: hidden;
  overflow-y: hidden;
  /* position: absolute;
  top: 0; */

  /* height: 100vh; */
  /* opacity: 0.5; */
  width: 100vw;
  min-height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.header_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 1002;
  /* margin-top: 200px; */
  height: 100vh;
}

.nav_header {
  position: fixed;
  position: -webkit-sticky;
  top: 0;
  display: flex;
  /* width: max-content; */
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  z-index: 1020;

  /* background-color: black; */

  transition: top 0.3s;
}
.nav-link {
  cursor: pointer;
}
.dropdown-menu {
  min-width: max-content !important;
}
.dropdown-item {
  font-weight: 700 !important;
  border-bottom: 1px solid lightgray;
}
.dropdown-menu.show {
  height: max-content !important;
}

.header_info > h1 {
  font-size: 4rem;
  margin-bottom: 30px;
}
.header_info > h4 {
  font-size: 2rem;
}
.header_info > p {
  color: rgb(197, 197, 197);
  font-size: 1.3rem;
}
.nav_header .navbar-collapse .navbar-nav .nav-link {
  color: white;
}
.navbar-dark:hover .navbar-nav:hover .nav-link:hover {
  color: orange;
}
#nav_work,
#nav_drop,
.nav-item,
.dropdown {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.header_nav {
  /* background-color: black;
  width: 100%;
  height: 60px;
  margin: auto; */
}
.active {
  color: orange !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
}
.header_nav {
  /* transition: background-color 0.9s; */

  /* background-color: #302c2c; */
  /* background-color: black; */
  width: 100%;
  height: 60px;
  margin: auto;
}
.header_backgroundNone {
  /* transition: visibility 4s; */
  display: none;
  /* visibility: hidden; */
}
.fixed-top {
  display: fixed;
  left: initial !important;
  right: initial !important;
}

a {
  text-decoration: none;
}
.icons > a > .fab {
  margin: 0 10px 15px 10px;
  font-size: 2.5rem;
  color: white;
  transition: color 0.3s;

  /* background-color: white !important; */
}
.icons > a > .fab:hover {
  color: rgb(54, 54, 54);
}
@media screen and (max-width: 500px) {
  .navbar-dark:hover .navbar-nav:hover .nav-link:hover {
    color: white;
  }
  .header {
    min-height: fit-content;
  }
  .header_info > h4 {
    font-size: 1.3rem;
  }
  .header_info > p {
    width: 100%;
    padding: 20px;
    font-size: 1rem;
  }
  .header_info > h1 {
    font-size: 1.4rem;
  }
  .icons > a > .fab {
    font-size: 2rem;
  }
}
@media screen and (min-width: 501px) and (max-width: 900px) {
  .header_info > h4 {
    font-size: 2rem;
  }
  .header_info > p {
    width: 100%;
    padding: 20px;
    font-size: 1rem;
  }
  .header_info > h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 991px) {
  .navbar-toggler:focus,
  .navbar-toggler:hover {
    /* text-decoration: none; */
    outline: none;
  }
  .navbar {
    background-color: #1a1a1a;
    opacity: 0.8;
    border-radius: 10px;
    padding: 0px !important;
    justify-content: center !important;
    top: 10px !important;
  }
  #video {
    width: fit-content;
    height: inherit;
  }
}

/* .caption {
  position: absolute;
  top: 0%;
  width: 100%;
  color: white;
} */
.video-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#video {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  z-index: -1000;
}
video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 101%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 1s opacity;
}
