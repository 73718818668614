.modal-body {
  background-color: black;
  /* background-color: rgb(17, 17, 17); */
  height: fit-content;
  text-align: center;
  align-items: center;
  opacity: 1;
}
.modal-body > img,
.img-fluid {
  opacity: 1;
}
a > button {
  background-color: #1a1a1a;
  color: white;
  outline: none !important;
  padding: 10px;
  border-radius: 5px;
  border-color: #1a1a1a;
}
.btn-modal-close {
  border-radius: 5px;
  border-color: #1a1a1a;
}
a > button:hover {
  background-color: white;
  color: #1a1a1a;
}
.modal-footer {
  justify-content: space-between;
}
a {
  text-decoration: none !important;
  color: white;
}
a:hover {
  text-decoration: none !important;
}
.button {
  position: absolute;
  top: -21px;
  left: -2px;
}
button > h6 {
  margin-bottom: 0;
}
.modal-open .modal {
  overflow-y: hidden;
}
.far:hover {
  cursor: pointer;
  color: grey;
  /* background-color: white; */
}
button.close {
  outline: none;
  border: none;
}
#btn-close {
  border: none !important;
  padding: 0;
  background-color: initial !important;
}
