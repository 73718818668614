.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 50%;
  height: 80vh;
  background-color: white;
  border-radius: 10px;
}
.form-bg {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
  height: 100vh;
}
.button {
  width: 100%;
  background-color: #1a1a1a;
}
.form-graphics {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
