.website {
  /* display: flex; */
  height: 100vh;
  background-color: #1a1a1a;
  color: white;

  /* padding-left: 12px; */
}
.website_section {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* align-items: center; */
  /* background-color: rgb(233, 233, 233); */
  text-align: center;
  /* overflow: hidden; */
  margin-top: 18px;
  /* margin-bottom: 20px; */
  background-color: #1a1a1a;
}
.web_img > img {
  border-color: white;
  box-shadow: 0px 1px 18px 0px rgba(150, 150, 150, 0.4);
  margin: 15px;
  /* box-shadow: -1px 5px 15px 0px rgba(0, 0, 0, 0.44); */
}
.website > a > .fas {
  padding-left: 20px;
  padding-top: 40px;
  color: white;
  font-size: 2.5rem;
}
.website > a > .fas:hover {
  cursor: pointer;
  color: grey;
  /* background-color: white; */
}
